:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-500{font-weight:500;}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._lh-f-lineHeigh112923{line-height:var(--f-lineHeight-4);}
:root ._col-color{color:var(--color);}
:root ._whiteSpace-normal{white-space:normal;}
:root ._cur-pointer{cursor:pointer;}
:root ._ussel-none{user-select:none;-webkit-user-select:none;}
:root ._col-textSeconda3655{color:var(--textSecondary);}