:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._cur-pointer{cursor:pointer;}
:root:root  ._bg-0active-backgroundP3496915:active{background-color:var(--backgroundPress) !important;}
@media (hover) {:root  ._bg-0hover-backgroundP3496915:hover{background-color:var(--backgroundPress) !important;}}
:root ._bg-backgroundP3496915{background-color:var(--backgroundPress);}
:root ._bg-transparent{background-color:transparent;}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._btlr-t-radius-0{border-top-left-radius:var(--t-radius-0);}
:root ._btrr-t-radius-0{border-top-right-radius:var(--t-radius-0);}
:root ._bbrr-t-radius-0{border-bottom-right-radius:var(--t-radius-0);}
:root ._bblr-t-radius-0{border-bottom-left-radius:var(--t-radius-0);}
:root ._fs-1{flex-shrink:1;}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._fg-1{flex-grow:1;}
:root ._btc-strokeSecon3075972{border-top-color:var(--strokeSecondary);}
:root ._brc-strokeSecon3075972{border-right-color:var(--strokeSecondary);}
:root ._bbc-strokeSecon3075972{border-bottom-color:var(--strokeSecondary);}
:root ._blc-strokeSecon3075972{border-left-color:var(--strokeSecondary);}
:root ._btw-0px{border-top-width:0px;}
:root ._bts-solid{border-top-style:solid;}
:root ._btw-0--5px{border-top-width:0.5px;}
:root ._bbw-0--5px{border-bottom-width:0.5px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._ai-flex-end{align-items:flex-end;}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._gap-t-space-1{gap:var(--t-space-1);}
:root ._h-t-size-4{height:var(--t-size-4);}
:root ._gap-t-space-5{gap:var(--t-space-5);}
:root ._ai-baseline{align-items:baseline;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._w-10037{width:100%;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-4{font-weight:var(--f-weight-4);}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._lh-f-lineHeigh112923{line-height:var(--f-lineHeight-4);}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-nowrap{white-space:nowrap;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._textOverflow-ellipsis{text-overflow:ellipsis;}
:root ._maw-10037{max-width:100%;}
:root ._miw-auto{min-width:auto;}