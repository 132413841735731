:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._w-10037{width:100%;}
:root ._jc-center{justify-content:center;}
:root ._pr-t-space-4--53{padding-right:var(--t-space-4--5);}
:root ._pl-t-space-4--53{padding-left:var(--t-space-4--5);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._ai-center{align-items:center;}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pr-t-space-5{padding-right:var(--t-space-5);}
:root ._pl-t-space-5{padding-left:var(--t-space-5);}
:root ._fs-1{flex-shrink:1;}
:root ._gap-t-space-3--53{gap:var(--t-space-3--5);}
:root ._fg-1{flex-grow:1;}
:root ._bg-backgroundT1477332154{background-color:var(--backgroundTertiary);}
:root ._w-42px{width:42px;}
:root ._h-42px{height:42px;}
:root ._btlr-t-radius-7{border-top-left-radius:var(--t-radius-7);}
:root ._btrr-t-radius-7{border-top-right-radius:var(--t-radius-7);}
:root ._bbrr-t-radius-7{border-bottom-right-radius:var(--t-radius-7);}
:root ._bblr-t-radius-7{border-bottom-left-radius:var(--t-radius-7);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-6{font-weight:var(--f-weight-6);}
:root ._fos-f-size-6{font-size:var(--f-size-6);}
:root ._lh-f-lineHeigh112925{line-height:var(--f-lineHeight-6);}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._fd-column{flex-direction:column;}
:root ._gap-t-space-0--53{gap:var(--t-space-0--5);}
:root ._fow-500{font-weight:500;}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._lh-f-lineHeigh112923{line-height:var(--f-lineHeight-4);}
:root ._fow-f-weight-3{font-weight:var(--f-weight-3);}
:root ._fos-f-size-3{font-size:var(--f-size-3);}
:root ._lh-f-lineHeigh112922{line-height:var(--f-lineHeight-3);}